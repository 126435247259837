import React         from 'react';
import { graphql }   from 'gatsby';
import Img           from 'gatsby-image';
import { Box, Flex } from '@chakra-ui/react';


import { findT }        from '@interness/web-core/src/components/_helpers';
import SEO              from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                        from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper          from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import BrandPageWrapper from '@interness/ecommerce-addon/src/components/BrandPageWrapper/BrandPageWrapper';
import Logo             from '@interness/web-core/src/components/media/Logo/Logo';

const BrandPageTemplate = props => {
  const metaData = props.data.allSitePlugin.nodes;
  const data = props.data.directusBrands.brand;
  const type = props.data.directusBrandTypes;
  const typeTranslations = findT(type.translations, props.pageContext.lang);

  let hasEcom = metaData.filter(plugin => plugin.name === '@interness/ecommerce-addon');
  hasEcom = hasEcom.length === 1;

  const category = typeTranslations.slug.charAt(0).toUpperCase() + typeTranslations.slug.slice(1);

  return (
    <>
      <SEO title={data.display_name}/>
      <BrandsSubnavigation type={data.type.type}/>
      <Spacer/>
      <div style={{ textAlign: 'center', height: '80px' }}>
        <div style={{ width: '230px', margin: 'auto' }}>
          <Logo logo={data.logo.localFile} title={data.display_name} svgWidth="240px" svgHeight="80px"/>
        </div>
      </div>
      <Spacer/>
      <Wrapper>
        {data.gallery_images.length > 0 &&
          <>
            <div>
              {data.gallery_images.map((image, i) => {
                const isEven = i % 2 === 0;
                return (
                  <div key={image.file.localFile.name}>
                    <Flex key={image.file.localFile.name}
                          flexWrap="wrap"
                          flexDirection={isEven ? 'row-reverse' : 'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'} mb={4}>
                      <Box w={['100%', '100%', '100%', '48%']}>
                        <Img fluid={image.file.localFile.childImageSharp.fluid} alt={image.file.localFile.name}/>
                      </Box>
                      <Box w={['100%', '100%', '100%', '48%']} pt={6} pl={isEven ? 0 : 6} pr={isEven ? 6 : 0}
                           textAlign="left">
                        <div dangerouslySetInnerHTML={{ __html: image.file.description }}/>
                      </Box>
                    </Flex>
                  </div>
                );
              })}
            </div>
            <Spacer/>
          </>}
        {data.vimeo_video_id &&
          <>
            <ResponsiveIFrame maxHeight={56.25} src={`https://player.vimeo.com/video/${data.vimeo_video_id}?dnt=1`}
                              title={`VimeoVideo`} type={'vimeo'}/>
            <Spacer/>
          </>}
        {hasEcom && <div>
          <BrandPageWrapper category={typeTranslations.slug} title={`${data.display_name} ${category}`}
                            manufacturer={data.slug}/>
        </div>}
      </Wrapper>
    </>
  )
};

export default BrandPageTemplate;

export const query = graphql`
    query($slug: String!, $type: String!) {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
            ...BrandHeaderImages
            ...BrandGalleryImages
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                slug
                language
            }
        }
        allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
            nodes {
                version
                name
            }
        }
    }
`;